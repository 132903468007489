<template>
  <div>
    <layout-balance-bar />
    <layout-nav-bar />
    <slot />
    <layout-footer />
  </div>
</template>
<style lang="postcss">
  @import "../assets/css/tailwind.css";

  body {
    @apply dmsans bg-ivory;
  }

  .web3modal-provider-icon {
    width: 90px;
  }

  @media screen and (max-width: 768px) {
    .web3modal-provider-icon {
      width: 17vw;
    }
  }
</style>
